.menuSuperior {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    /* background-color: rgb(4, 35, 68);
    color: #fff; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 20px;
}

.menuSuperior i {
    font-size: 20px;
    cursor: pointer;
}

.derechaMenuSuperior {
    display: flex;
}

.derechaMenuSuperior p {
    margin: 0;
    padding: 0;
    padding-top: 2px;
    margin-right: 10px;
}

.cerrarSesion {
    color: red;
    font-weight: bold !important;
}