.contenedorEtapas {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.gantt {
    width: 49%;
}

.tituloGantt {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    padding-right: 10px;
}

.tituloGantt p {
    margin: 0;
    padding: 0;
}

.accordion-button {
    box-shadow: none !important;
}

.accordion-item p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
}

.accordion-item ul {
    padding: 0;
    padding-left: 5px;
    margin: 0;
}

.accordion-item ul li {
    list-style: none;
    margin-bottom: 3px;
}

.negrita {
    font-weight: bold;
}

.contenedorKpis {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.kpis {
    width: 49%;
}

.kpis h3 {
    margin-left: 10px;
}

.kpi {
    width: 48%;
    height: 100px;
    margin-left: 2%;
    margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    padding: 10px;
    display: flex;
}

.kpi p {
    margin: 0;
    padding: 0;
}

.iconoAvance {
    width: 30%;
    display: flex;
    align-items: center;
}

.iconoAvance i {
    font-size: 45px;
}

.numeroYtextoAvance {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
}

.cantidadAvance {
    font-size: 30px;
    height: 40px;
}

.porcentajeAvance {
    font-size: 20px;
    margin-left: 2px;
}

.tituloKpi {
    font-size: 16px;
}

.firmasbbp {
    font-size: 14px !important;
}

@media (max-width: 600px) {
    .contenedorEtapas {
        width: 100%;
        flex-direction: column;
    }

    .gantt {
        width: 100%;
    }

    .kpis h3 {
        margin-left: 0;
        margin-top: 20px;
    }

    .kpis {
        width: 100%;
    }

    .contenedorKpis {
        justify-content: space-between !important;
    }

    .kpi {
        margin-left: 0;
    }

    .cantidadAvance {
        font-size: 20px;
        height: 30px;
    }
    
    .iconoAvance i {
        font-size: 30px;
    }

    .tituloKpi {
        font-size: 14px;
        text-align: right;
        padding-left: 5px !important;
    }

    .iconoAvance {
        width: 20%;
    }

    .numeroYtextoAvance {
        width: 80%;
    }
}