.login {
    height: 100vh;
    /* padding: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(62,84,107);
    background: linear-gradient(180deg, rgba(62,84,107,1) 0%, rgba(62,84,107,1) 52%, rgba(94,117,143,1) 100%);
    /* background-color: #eeeeee;; */
}

.ingresar {
    width: 400px;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 5px;
}

.logo {
    text-align: center; 
}

.ingresar img {
    width: 60%;
    margin-bottom: 10px;
}

.btn-login {
    width: 100%;
    background-color: #376FA4 !important;
    color: white !important;
}

.textoIngresar {
    padding:0;
    padding-bottom: 2px;
    margin: 0;
}

.olvidemicontrasena {
    display: flex;
    align-items: center;
    justify-content: center;
}

.olvidemicontrasena a {
    text-decoration: none;
    font-size: 14px;
}