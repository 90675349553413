.contenedorSidebar {
    width: 20%;
    width: 300px;
    min-height: 100vh;
    top: 0;
    left: 0;
    /* background: rgb(62,84,107); */
    /* background-color: rgb(4, 35, 68); */
    background-color: #fff;
    /* background: rgb(62,84,107); */
    /* background: linear-gradient(180deg, rgba(62,84,107,1) 0%, rgba(62,84,107,1) 52%, rgba(94,117,143,1) 100%); */
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
    /* color: #fff; */
  }
  
  .cabeceraSidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .contenedorImgLogo {
    width: 100%;
    text-align: center;
  }

  .cabeceraSidebar img {
    width: 65%;
    margin: 15px 0;
  }

  .closeContenedorSidebar {
    margin-left: -300px;
    /* margin-left: -245px; */
  }

  @media (max-width: 1200px) {
    .contenedorSidebar {
      width: 70% !important;
      max-width: 300px;
      position: absolute;
      top: 0;
      left: -70%;
      z-index: 6 !important;
    }
  
    .fondoNegroSidebar {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #000;
      opacity: 0.7;
      z-index: 5 !important;
      display: block;
      transition: all 0.3s;
    }
  
    .closeContenedorSidebar {
      margin-left: 70%;
    }
  }