.cardFecha {
    width: 277px !important;
    min-height: 150px;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    /* margin-right: 10px; */
    margin-bottom: 10px;
    margin-right: 10px;
}

.fecha {
    font-size: 25px;
}

.cardFecha h2 {
    font-size: 20px;
    color: rgb(62,84,107);
    margin-top: 20px;
}

.btn-documento {
    background-color: rgb(62,84,107) !important;
    color: #fff;
    padding: 1px 7px !important;
    border: 2px solid rgb(62,84,107) !important;
    margin-bottom: 2px;
}

.btn-documento:hover {
    background-color: #fff !important;
    border: 2px solid rgb(62,84,107);
    color: rgb(62,84,107);
}

.btn-invite {
    background-color: rgb(59, 158, 19) !important;
    color: #fff;
    padding: 1px 7px !important;
    border: 2px solid rgb(59,158, 19) !important;
    margin-bottom: -10px;
}

.btn-invite:hover {
    background-color: #fff !important;
    border: 2px solid rgb(62,84,107);
    color: rgb(62,84,107);
}

.contenedorIconoEvento {
    display: flex;
    justify-content: space-between;
}

.editar {
    font-size: 14px;
}

.btn-editar {
    padding: 0 3px 0 3px !important;
    margin-bottom: 3px;
}

.btn-editar:focus {
    box-shadow: none;
}

@media (max-width:600px) {
    .cardFecha {
        width: 100%;
    }

    .cardFecha h2 {
        font-size: 15px;
    }

    .fecha {
        font-size: 20px;
    }

    .contenedorIconoEvento p {
        font-size: 14px !important;
    }
}
