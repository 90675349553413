.encabezadoFlex {
    display: flex;
    justify-content: space-between;
}

.btn-agregar {
    height: 40px;
    padding: 2px 10px !important;
}

.contenedorListado {
    width: 100%;
    border-radius: 5px;
    /* background-color: rgb(240, 240, 240); */
    /* background-color: #fff; */
    /* padding: 10px 10px 1px 10px ; */
    margin-top: 10px;
}

.encabezadoListado {
    width: 100%;
    background-color: #fff;
    border-left: 5px solid rgb(255, 255, 255);
    /* border: 1px solid rgb(62,84,107); */
    /* border: 1px solid #f4c22c; */
    /* border-radius: 5px; */
    padding: 10px 5px;
    display: flex;
    margin-bottom: 20px;
}

.encabezadoListado p {
    margin: 0;
}

.numeroItem {
    width: 10%;
    text-align: center;
    font-size: 14px;
}

.nombreItem {
    width: 30%;
    padding-left: 10px;
}

.contenedorNombreEstado {
    width: 45%;
}

.btnItem {
    width: 100%;
    padding-left: 10px;
    text-align: center;
}

.estadoItem {
    width: 100%;
    padding-left: 10px;
    text-align: center;
}

.accionItem {
    width: 15%;
    padding-left: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.itemListado {
    background-color: #fff;
    padding: 12px 5px;
    /* border-radius: 5px; */
    border-left: 5px solid rgb(62,84,107);
    border-left: 5px solid #f4c22c;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.itemListado p {
    margin: 0;
}

.btn-documento {
    /* background-color: rgb(62,84,107) !important;
    color:rgb(255, 255, 255) !important; */
    background-color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 2px 5px;
}

.aRevisarKU {
    color: rgb(228, 38, 38);
    font-size: 14px;
}

.respondido {
    color: rgb(33, 163, 33);
    font-size: 14px;
}

.btn-enviar {
    background-color: #F7C344;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 2px 5px;
    color: rgb(96, 96, 96);
}

.btn-enviar-disabled {
    background-color: #c1c1c1;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    padding: 2px 5px;
    color: rgb(96, 96, 96);
}

.btn-confirmar-disabled {
    background-color: #c1c1c1;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    padding: 2px 5px;
    color: rgb(96, 96, 96);
    margin-left: 5px;
}

.btn-confirmar {
    background-color: #408558;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    padding: 2px 5px;
    color: rgb(255, 255, 255);
    margin-left: 5px;
}

.contenedorNombreEstado {
    display: flex;
}

.nohayrelevamientos {
    width: 100%;
    color: black;
    font-size: 20px;
    text-align: center;
    margin: 50px 0 50px 0;
}

.contenedorCargando {
    display: flex;
    justify-content: center;
    padding: 30px 0 50px 0;
}

@media (max-width: 600px) {
    .encabezadoListado, .numeroItem {
        display: none;
    }

    .itemListado {
        flex-direction: column;
    }

    .contenedorNombreEstado {
        width: 100%;
        margin: 10px 0;
    }

    .estadoItem {
        width: 45%;
        padding-top: 1px;
    }

    .btnItem {
        width: 55%;
        text-align: left !important;
    }

    .btn-item {
        font-size: 14px !important;
        text-align: left;
    }

    .nombreItem {
        width: 100%;
        font-weight: bold;
    }

    .accionItem {
        width: 100%;
        padding: 0;
    }

    .btn-enviar-disabled, .btn-confirmar-disabled, .btn-enviar, .btn-confirmar {
        width: 50%;
    }
}