.menu {
    margin-top: 0;
}

.titulo-menu {
    padding-left: 10px;
}

.titulo-menu-admin {
    margin-top: 20px;
}

.titulo-menu p {
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    margin: 0 !important;
    margin-bottom: 5px !important;
    font-weight: bold;
}

.menu ul {
    list-style-type: none;
    padding: 0 15px;
    margin: 0;
}

.menu ul a {
    text-decoration: none;
    color: rgb(54, 54, 54);
    /* color: #fff; */
    /* color: #c9c9c9; */
}

.menu ul li {
    /* background-color: rgb(240, 240, 240); */
    margin-bottom: 12px;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 5px;
}

.menu ul li:hover {
    background-color: rgb(62,84,107);
    /* background-color: #3484c5; */
    color: #fff;
    cursor: pointer;
    /* background-color: #fff; */
    /* color: #3484c5; */
}

.active {
    background-color: rgb(62,84,107);
    /* background-color: #3484c5; */
    color: #fff;
    cursor: pointer;
}