.contenedorContenido {
    height: calc(100vh - 80px) !important;
    overflow-y: scroll;
}

.contenedorContenido h3 {
    margin: 0 0 20px 0;
    color: #fff;
    /* color: rgb(4, 35, 68); */
}

.contenedorCardsFechas {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.contenedorIconoFecha {
    border-bottom: 1px solid rgb(187, 187, 187);
    margin-bottom: 10px;
}

.contenedorIconoEvento {
    border-bottom: 1px solid rgb(187, 187, 187);
    margin-bottom: 10px;
    display: flex;
}

.contenedorIconoEvento p {
    font-size: 14px;
    margin: 0;
    margin-left: 5px;
    margin-top: 1px;
}

.nohayeventos {
    width: 100%;
    margin-top: 100px;
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.contenedorCardsFecha {
    /* width: 24%; */
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 600px) {
    .nohayeventos {
        font-size: 18px;
    }

    .contenedorCardsFecha {
        width: 49%;
    }

    .gantt {
        width: 100%;
    }

    .tituloGantt p {
        margin: 0;
        padding: 0;
        font-size: 14px;
    }
}