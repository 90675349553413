.contenidoPlataforma {
  width: 100%;
  height: 100vh;
  /* background: rgb(62,84,107); */
  /* background-color: rgb(230, 230, 230); */
  padding: 10px;
  background: linear-gradient(180deg, rgba(62,84,107,1) 0%, rgba(62,84,107,1) 52%, rgba(94,117,143,1) 100%);
  /* background-color: #eeeeee; */
}

.contenedorContenido {
  padding: 10px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.contenedorContenido::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.contenedorEquipo {
  padding: 10px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.contenedorEquipo::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}