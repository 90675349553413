.numeroItem {
    width: 10%;
    text-align: center;
    font-size: 14px;
}

.contenedoDocumentoEstado {
    width: 75%;
    display: flex;
}

.tituloDocumento {
    width: 50%;
    padding-left: 10px;
}

.fechaDocumento {
    width: 50%;
    padding-left: 10px;
    text-align: center;
}

.accionItem {
    width: 15%;
    padding-left: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
}

@media (max-width: 600px) {
    .contenedoDocumentoEstado {
        width: 100%;
        justify-content: space-between;
    }

    .fechaDocumento {
        width: 30%;
        text-align: right;
        padding-left: 0;
    }

    .tituloDocumento {
        width: 70%;
        font-weight: bold;
    }

    .accionItem {
        width: 100%;
        padding: 0;
        padding-top: 10px;
    }

    .accionItem a {
        width: 100%;
        margin: 0;
    }

    .accionItem a button {
        width: 100%;
    }
}