.cardEquipo {
    /* width: 275px; */
    width: 22%;
    min-height: 200px;
    border-radius: 5px;
    background-color: #fff;
    padding: 20px 10px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
}

.cardEquipo img {
    width: 50%;
    margin: 0 auto;
}

.cardEquipo h4 {
    margin-top: 10px;
    margin-bottom: 15px;
    color: rgb(25, 113, 206);
}

.cardEquipo h5 {
    font-size: 16px;
}

@media (max-width: 600px) {
    .cardEquipo {
        width: 100%;
    }
}